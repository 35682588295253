<template>
  <div
    class="auth-wrapper auth-v2 w-100 h-100"
    :style="backgroundStyle"
  >
    <b-spinner v-if="isLoading" type="grow" class="mt-5 mx-auto d-block" />
    <b-row v-else class="auth-inner m-0" align-v="center">
      <b-col
        md="6"
        offset-md="3"
        class="d-flex align-items-center my-0 p-0 my-md-2"
      >
        <b-card
          v-if="isValid && canRegister"
          class="px-3 px-md-2 mx-auto my-0 my-md-2 auth-bg w-100"
        >
          <safe-img :src="communityLogo" class="signup-logo mb-1 mt-1 round" />
          <h1
            v-if="community"
            class="font-weight-bold w-100 text-center mb-2"
            title-tag="h1"
          >
            {{ translate(community.name) }}
          </h1>
          <b-card-text class="text-center h4">
            {{ $t("sign-up.member-subtitle") }}
          </b-card-text>
          <b-card-text class="text-center">
            {{ $route.query.email }}
          </b-card-text>
          <b-form class="auth-login-form mt-2" @submit.prevent="register">
            <!-- name -->
            <b-form-group>
              <label for="sign-up-name">{{
                $t("sign-up.form.name.label")
              }}</label>
              <b-form-input
                id="sign-up-name"
                v-model="name"
                required
                :placeholder="$t('sign-up.form.name.placeholder')"
              />
            </b-form-group>
            <!-- surname -->
            <b-form-group>
              <label for="sign-up-surname">{{
                $t("sign-up.form.surname.label")
              }}</label>
              <b-form-input
                id="sign-up-surname"
                v-model="surname"
                :placeholder="$t('sign-up.form.surname.placeholder')"
              />
            </b-form-group>
            <!-- password -->
            <b-form-group>
              <label for="sign-up-password">{{
                $t("sign-up.form.password.label")
              }}</label>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="sign-up-password"
                  v-model="password"
                  class="form-control-merge"
                  type="password"
                  name="sign-up-password"
                  :placeholder="$t('sign-up.form.password.placeholder')"
                  :label="$t('sign-up.form.password.label')"
                  required
                  aria-describedby="passwordHelp"
                />
              </b-input-group>
              <small
                v-if="password.length < 6 && password.length > 0"
                id="passwordHelp"
                class="form-text text-danger"
              >{{ $t("sign-up.form.password.min-characters") }}</small>
            </b-form-group>
            <!-- Repeat password -->
            <b-form-group>
              <label for="sign-up-password2">{{
                $t("sign-up.form.repeat-password.label")
              }}</label>

              <b-input-group class="input-group-merge">
                <b-form-input
                  id="sign-up-password2"
                  v-model="password2"
                  class="form-control-merge"
                  type="password"
                  name="sign-up-password2"
                  required
                  :placeholder="$t('sign-up.form.repeat-password.placeholder')"
                  :label="$t('sign-up.form.repeat-password.label')"
                />
              </b-input-group>
              <small
                v-if="password2.length < 6 && password2.length > 0"
                id="passwordHelp"
                class="form-text text-danger"
              >{{ $t("sign-up.form.password.min-characters") }}</small>
            </b-form-group>
            <!-- Select modality -->
            <b-form-group v-if="typeOfEvent === 'hybrid'">
              <label for="sign-up-modality">{{
                $t("sign-up.form.modality.label")
              }}</label>

              <b-input-group class="input-group-merge">
                <v-select
                  v-model="modality"
                  :options="valuesOfSelect"
                  style="border: 0px; width: 100%; margin-top: 5px"
                  :clearable="false"
                />
              </b-input-group>
              <small
                v-if="password2.length < 6 && password2.length > 0"
                id="passwordHelp"
                class="form-text text-danger"
              >{{ $t("sign-up.form.password.min-characters") }}</small>
            </b-form-group>

            <!-- Terms conditions & Privacy policy -->

            <b-card-text class="mb-2">
              <b-form-checkbox
                id="checkboxTerms"
                v-model="acceptTerms"
                name="checkboxTerms"
                value="true"
                unchecked-value="false"
                required
              >
                <span>{{ $t("sign-up.have-accept") }} </span>
                <b-link :href="termsConditionsURL">
                  <span>{{ $t("header.action.terms-conditions") }}</span>
                </b-link>
                <span> {{ $t("sign-up.and") }} </span>
                <b-link :href="privacyPolicyURL">
                  <span>{{ $t("header.action.privacy-policy") }}</span>
                </b-link>
              </b-form-checkbox>
            </b-card-text>

            <!-- Error Toast -->
            <div class="error-toast mb-1">
              <b-alert
                v-if="isWrongPassword"
                show
                variant="danger"
                class="error-notification m-0"
              >
                {{ $t("sign-up.error-message.password") }}
              </b-alert>
              <b-alert
                v-if="invalidCredentials"
                show
                variant="danger"
                class="m-0 error-notification"
              >
                {{ $t("sign-up.error-message.invalid-credentials") }}
              </b-alert>
              <b-alert
                v-if="showTermsError"
                show
                variant="danger"
                class="m-0 error-notification"
              >
                {{ $t("sign-up.error-message.checkbox") }}
              </b-alert>
              <b-alert
                v-if="generalError"
                show
                variant="danger"
                class="m-0 error-notification"
              >
                {{ $t("error-message.general-error") }}
              </b-alert>
            </div>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              :disabled="
                name === '' || password.length < 6 || password2.length < 6
              "
              block
            >
              {{ $t("sign-up.form.action.sign-in") }}
            </b-button>
          </b-form>
          <b-card-text class="text-center small mt-1">
            <b-link :href="loginURL">
              {{ $t("sign-up.form.action.cancel") }}
            </b-link>
          </b-card-text>

          <b-card-text class="text-center mt-2">
            <b-link :href="landingURL" target="_blank">
              <b-img :src="brand" alt="Power By Nectios" class="brand" />
            </b-link>
          </b-card-text>
        </b-card>
        <b-card
          v-else
          class="px-3 px-md-2 mx-auto my-0 my-md-2 auth-bg w-100 text-center"
        >
          <div>
            <p v-if="!canRegister" class="font-weight-bolder mt-2">
              El registro a la comunidad está cerrado.
            </p>
            <p v-else class="font-weight-bolder mt-2">
              El token no es valido o ha expirado.
            </p>
            <b-link :href="loginURL">
              Ir al login
            </b-link>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getImageResource } from '@/@core/utils/image-utils';
import BrandLogo from '@/assets/images/brand/powerby-nectios.svg';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import Service from '@/config/service-identifiers';
import Vue from 'vue';
import { getDomain } from '@/store/auth/auth.store';
import vSelect from 'vue-select';
import { getNewAuthRoute } from '@/auth/utils';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'AuthRegister',
  components: {
    SafeImg,
    vSelect,
  },
  data() {
    return {
      name: '',
      surname: '',
      password: '',
      password2: '',
      modality: '',

      isLoading: true,
      community: null,
      isValid: false,

      from: null,
      path: null,

      isWrongPassword: false,
      userExist: false,
      invalidCredentials: false,
      generalError: false,
      acceptTerms: false,
      showTermsError: false,
      canRegister: true,
    };
  },

  computed: {
    communitySlug() {
      return this.$route.params.communityId;
    },
    termsConditionsURL() {
      return (
        `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
          '{subdomain}',
          this.community?.subdomain || 'app',
        ).replace('{slug}', this.communitySlug)}/?legal=terms_conditions`
      );
    },
    privacyPolicyURL() {
      return (
        `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
          '{subdomain}',
          this.community?.subdomain || 'app',
        ).replace('{slug}', this.communitySlug)}/?legal=privacy_policy`
      );
    },
    backgroundStyle() {
      const backgrounds = this.community?.customization?.theme?.login?.backgrounds || [];
      if (backgrounds.length) {
        return {
          backgroundImage: `url(${getImageResource(backgrounds[0])}`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat !important',
        };
      }
      return null;
    },
    typeOfEvent() {
      if (['Event', 'Project', 'Challenge', 'Course'].includes(this.community.mainType)
      ) {
        if (
          this.community.occupation?.online != null
          && this.community.occupation?.['on-site'] != null
        ) {
          return 'hybrid';
        } if (this.community.occupation?.['on-site']) {
          return 'on-site';
        }
        ('online');
      } else {
        return false;
      }
    },
    canOnline() {
      if (this.typeOfEvent === 'hybrid') return this.community?.onlineOccupation < this.community.occupation?.online;
      if (this.typeOfEvent === 'online') return this.community?.currentOccupation < this.community.occupation?.online;
    },
    canOnsite() {
      if (this.typeOfEvent === 'hybrid') return (this.community.onsiteOccupation < this.community.occupation?.['on-site']);
      if (this.typeOfEvent === 'on-site') return (this.community?.currentOccupation < this.community.occupation?.['on-site']);
    },
    valuesOfSelect() {
      const ob = [];
      if (this.canOnline) {
        ob.push('Online');
        this.modality = 'Online';
      }
      if (this.canOnsite) {
        ob.push('On-site');
        if (!this.canOnline) {
          this.modality = 'On-site';
        }
      }
      return ['online', 'on-site'];
    },
    signUpURL() {
      let url = process.env.VUE_APP_SIGN_UP_URL.replace(
        '{subdomain}',
        this.community?.subdomain || 'app',
      ).replace('{slug}', this.communitySlug);
      url = `${url}?code=${this.community.language}`;

      return url;
    },
    loginURL() {
      return process.env.VUE_APP_LOGIN_TEMPLATE_URL_OLD.replace(
        '{collectiveSubdomain}',
        this.community?.subdomain || 'app',
      ).replace('{collectiveSlug}', this.communitySlug);
    },
    communityLogo() {
      return getImageResource(
        this.community?.customization?.theme?.login?.logo
          || this.community?.logoURL
          || this.community?.header?.logo_url,
      );
    },
    brand() {
      return BrandLogo;
    },
    subscriptionPlan() {
      return this.$store.getters.subscriptionPlans.unpaginated[0] || [];
    },
    landingURL() {
      return process.env.VUE_APP_LANDING_URL_COMMUNITY;
    },
    showChatbot() {
      return this.$store.getters.currentCollective
        ? this.$store.getters.currentCollective?.showChatbot
        : null;
    },
    chatbotCustom() {
      return this.$store.getters.currentCollective
        ? this.$store.getters.currentCollective?.customChatbot
        : null;
    },
  },
  watch: {
    communitySlug() {
      this.updateCommunity();
    },
    setLocale(newLocale) {
      this.$store.dispatch('setLocale', newLocale);
    },
  },
  async created() {
    Vue.$cookies.set('community', this.$route.params.communityId);
    await this.updateCommunity();
    if (process.env.VUE_APP_ENV !== 'development') {
      window.location.href = getNewAuthRoute({ ...this.community, slug: this.communitySlug }, `/register?token=${this.$route.query.token}`);
      return;
    }
    if (this.communitySlug === 'barcelona-activa') {
      window.location.href = this.loginURL;
    }
    if (this.$route.query.token == null) {
      window.location.href = this.loginURL;
    }
    await this.checkToken();
    if (this.typeOfEvent) {
      if (!this.canOnline && !this.canOnsite) {
        this.canRegister = false;
      }
    }
  },

  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    async updateCommunity() {
      this.isLoading = true;

      const response = await this.$store.$service[Service.BackendClient].get(
        'login',
        {
          params: { communitySlug: this.communitySlug },
        },
      );

      this.community = response.data.data;
      /* Community colors */
      if (this.community.community_customizations.theme) {
        const customization = this.community.community_customizations.theme;
        if (customization.primaryColor) {
          document.documentElement.style.setProperty(
            '--primary-color',
            customization.primaryColor,
          );
          let hsl = customization.primaryColor;
          hsl = hsl.substring(4, hsl.length - 1);
          hsl = hsl.split(',');
          document.documentElement.style.setProperty(
            '--primary-color-h',
            hsl[0],
          );
          document.documentElement.style.setProperty(
            '--primary-color-s',
            hsl[1],
          );
          document.documentElement.style.setProperty(
            '--primary-color-l',
            hsl[2],
          );
        }
        if (customization.secondaryColor) {
          document.documentElement.style.setProperty(
            '--secondary-color',
            customization.secondaryColor,
          );
          let hslSecondary = customization.secondaryColor;
          hslSecondary = hslSecondary.substring(4, hslSecondary.length - 1);
          hslSecondary = hslSecondary.split(',');
          document.documentElement.style.setProperty(
            '--secondary-color-h',
            hslSecondary[0],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-s',
            hslSecondary[1],
          );
          document.documentElement.style.setProperty(
            '--secondary-color-l',
            hslSecondary[2],
          );
        }
      }
      /* Finish colors */
      // if (this.community.showChatbot === 1) {
      //   const chatBot = document.createElement('script');
      //   chatBot.innerText = 'window.$crisp=[];window.CRISP_WEBSITE_ID="a1aa36a2-c0e5-44a4-bc75-c5f95f1af801";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();';
      //   document.body.appendChild(chatBot);
      // }
      if (this.community.showChatbot === 2) {
        const chatBot = document.createElement('div');
        chatBot.innerHTML = this.$store.getters.currentCollective?.customChatbot;
        document.body.appendChild(chatBot);
      }

      if (
        this.community.mainCommunitySlug
        && this.community.mainCommunitySlug !== this.communitySlug
        && !this.community.bypass
      ) {
        this.from = this.communitySlug;
        this.path = this.$route.params.path;
        await this.$router.replace({
          name: 'auth-register',
          params: { communityId: this.community.mainCommunitySlug },
          query: this.$route.query,
        });
        return;
      }
      this.setLocale();
    },
    async checkToken() {
      const response = await this.$store.$service[Service.BackendClient].get(
        'validToken',
        {
          params: {
            communitySlug: this.communitySlug,
            token: this.$route.query.token,
          },
        },
      );

      this.isValid = response.data?.isValid;
      if (response.data?.member != null) {
        this.name = response.data?.member?.name;
        this.surname = response.data?.member?.surname;
      }
      this.isLoading = false;
    },
    setLocale() {
      this.$store.dispatch('setLocale', this.community.language);
    },
    async register() {
      this.isWrongPassword = false;
      this.showTermsError = false;
      if (this.acceptTerms) {
        if (this.password === this.password2) {
          try {
            await this.$store.dispatch('register', {
              name: this.name,
              surname: this.surname,
              invited: this.$route.query.invited != null ? 1 : undefined,
              password: this.password,
              modality:
                this.modality !== '' ? this.modality.toLowerCase() : undefined,
              token: this.$route.query.token,
              communitySlug: this.communitySlug,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });

            Vue.$cookies.set(
              'registeredTo',
              this.communitySlug,
              '1y',
              '/',
              getDomain(),
              true,
              'Strict',
            );

            return this.$router.replace({
              name: 'Resumen',
              params: { communityId: this.communitySlug },
            });
          } catch (error) {
            if (error.response.status === 400 && error.response.data.nextStep) {
              this.$router.replace({
                name: this.path,
                params: { communityId: this.$route.params.communityId },
              });
            } else if (error.response.status === 400) {
              this.invalidCredentials = true;
            } else {
              this.generalError = true;
            }
          }
        } else {
          this.isWrongPassword = true;
        }
      } else {
        this.showTermsError = true;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/page-auth.scss";

.signup-logo {
  width: 120px;
  margin: auto;
  display: block;
}
.brand {
  height: 32px;
}
.error-notification {
  font-size: 12px;
}
.community-text {
  padding-left: 6px;
  margin-top: 4px;
  vertical-align: bottom;
  color: black;
}
.col {
  height: 100vh;
}

@media (max-width: 767px) {
  .card {
    height: 100%;
    border-radius: 0;
  }
}
</style>
